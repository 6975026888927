import React, { useState, useEffect } from "react";
import Grid from "@mui/system/Unstable_Grid";
import {
  useClickedValue,
  useSearchValue,
  useMediaValue,
} from "../components/Context/Context";
import Poster from "../components/Posters/Poster";
import Overlay from "../components/Movie info/Overlay";

const Movies = () => {
  const [allMovies, setAllMovies] = useState(null);
  const [movieInfo, setMovieInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { action } = useClickedValue();
  const { searchValue } = useSearchValue();
  const { mediaValue } = useMediaValue();
  const [showOverlay, setShowOverlay] = useState(false);
  const GetCatId = require("../components/GetCatId/GetCatId");

  const movieSelected = async (element) => {
    const fetchData = async () => {
      let url;
      let id;
      if (mediaValue === "Movies") {
        id = element.stream_id;
        url = `api/?action=GetMovieInfo&vod_id=${element.stream_id}`;
      } else if (mediaValue === "Series") {
        id = element.series_id;
        url = `api/?action=GetSerieInfo&series_id=${element.series_id}`;
      }
      const response = await fetch(url);
      const result = await response.json();
      result.id = id;
      setMovieInfo(result);
    };
    await fetchData();
    setShowOverlay(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (searchValue !== null) {
        let url = "";
        if (mediaValue === "Movies") {
          url = `api/?action=SearchMovie&searchValue=${searchValue}`;
        } else if (mediaValue === "Series") {
          url = `api/?action=SearchSerie&searchValue=${searchValue}`;
        }
        try {
          const response = await fetch(url);

          if (!response.ok) {
            console.error("Error fetching data");
            return <h1>ERROR ERROR</h1>;
          }

          const data = await response.json();

          setAllMovies(data);
          setLoading(false);
        } catch (error) {
          console.error("Error:", error);
          setLoading(false);
        }
      } else {
        let url = "";
        let catId = GetCatId(action, mediaValue);
        if (mediaValue === "Movies") {
          url = `api/?action=GetMovieByCategory&category_id=${catId}`;
        } else if (mediaValue === "Series") {
          url = `api/?action=GetSeriesByCategory&category_id=${catId}`;
        } else if (mediaValue === "Lives") {
          url = `api/?action=GetLive`;
        }
        try {
          const response = await fetch(url);

          if (!response.ok) {
            console.error("Error fetching data");
            return <h1>ERROR</h1>;
          }

          const result = await response.json();

          setAllMovies(result);
          setLoading(false);
        } catch (error) {
          console.error("Error:", error);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [action, searchValue, mediaValue, GetCatId]);

  const handleOverlayToggle = () => {
    setShowOverlay(!showOverlay);
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    try {
      return (
        <>
          {showOverlay && (
            <Overlay info={movieInfo} onClose={handleOverlayToggle} />
          )}

          <Grid container spacing={1} style={{ margin: "auto" }}>
            {allMovies.map((element, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
                key={index}
                style={{ maxWidth: "600px" }}
              >
                <Poster
                  key={element.num}
                  info={element}
                  onClick={() => movieSelected(element)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      );
    } catch {
      console.log("allMovies" + allMovies);
      return <h1>ERROR : AllMovies = {allMovies}</h1>;
    }
  }
};

export default Movies;
