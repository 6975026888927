// LoginForm.js
import React, { useState, useEffect } from "react";
import { useTokenValue } from "../Context/Context";
import Button from "@mui/material/Button";
import * as FaIcons from "react-icons/fa";
import "./login.css";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { handleToken } = useTokenValue(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    const fetchData = async () => {
      if (storedToken) {
        try {
          const response = await fetch(
            "https://kaka-movies-login-api-c33204258d5f.herokuapp.com/verify_token",
            {
              method: "GET",
              headers: { Authorization: `Bearer ${storedToken}` },
            }
          );
          if (response.ok) {
            log(storedToken);
          }
        } catch {}
      }
    };
    fetchData();
  }, [token]);

  const log = (token) => {
    handleToken(token);
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(
        "https://kaka-movies-login-api-c33204258d5f.herokuapp.com/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setToken(data.access_token);
        localStorage.setItem("accessToken", data.access_token);
      } else {
        setErrorMessage("Mot de passe incorrect. Veuillez réessayer."); // Définir le message d'erreur
        setTimeout(() => {
          setErrorMessage(""); // Effacer le message d'erreur après un certain délai
        }, 3000); // 3000 millisecondes (3 secondes) ici, ajustez selon vos besoins
      }
    } catch (error) {
      console.error("Erreur lors de la tentative de connexion", error);
    }
  };

  return (
    <div className="page">
      <div className="login-box">
        <h2>Accéder au site</h2>
        <form>
          <div className="user-box">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label>Password</label>
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="show-password-button"
            >
              {showPassword ? <FaIcons.FaRegEyeSlash /> : <FaIcons.FaRegEye />}
            </button>
          </div>
          <div className="bouton">
            <Button variant="contained" onClick={handleLogin}>
              Accéder
            </Button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
