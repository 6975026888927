// index.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  ClickedValueProvider,
  SearchValueProvider,
  TokenValueProvider,
  MediaValueProvider,
} from "./components/Context/Context";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ClickedValueProvider>
    <SearchValueProvider>
      <TokenValueProvider>
        <MediaValueProvider>
          <App />
        </MediaValueProvider>
      </TokenValueProvider>
    </SearchValueProvider>
  </ClickedValueProvider>
);

reportWebVitals();
