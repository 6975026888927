import React, { useState, useEffect } from 'react';
import { Input } from '@mui/base/Input';
import { useTheme } from '@mui/system';
import { useSearchValue, useClickedValue,useMediaValue } from '../Context/Context';

function Search() {
  const [searchValue, setSearchValue] = useState('');
  const { handleSearch } = useSearchValue();
  const { mediaValue } = useMediaValue();
  const { action } = useClickedValue();

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      if(searchValue === ""){
        handleSearch(null);
      }
      else{
        handleSearch(searchValue);
      }
    }
  };

useEffect(() => {
  setSearchValue("")
},[action, mediaValue])

  return (
    <React.Fragment>
      <Input
        slotProps={{ input: { className: 'CustomInputIntroduction' } }}
        aria-label="Demo input"
        placeholder="Rechercher..."
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleEnterPress}
      />
      <Styles />
    </React.Fragment>
  );
}

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}

function Styles() {
  const isDarkMode = useIsDarkMode();

  return (
    <style>
      {`
        .CustomInputIntroduction {
          width: 250px;
          height: 10px;
          font-family: 'IBM Plex Sans', sans-serif;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
          padding: 8px 12px;
          border-radius: 8px;
          color: ${isDarkMode ? 'white' : 'dark'}; // Ajoutez la logique pour le mode sombre
          background: ${isDarkMode ? 'black' : 'white'}; // Ajoutez la logique pour le mode sombre
        }
      `}
    </style>
  );
}

export default Search;
