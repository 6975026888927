import React from 'react';
import { Menu } from '@mui/base/Menu';
import { MenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';

const DropdownMenu = ({ name, list, onItemClick }) => {
  return (
    <Dropdown
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            maxHeight: '100%', // La liste ne dépassera pas la hauteur de sa div mère
            overflow: 'auto', // Ajout de la possibilité de scroll
          },
        },
      }}
    >
      <MenuButton key={name} className="TriggerButtonIntroduction" autoFocus>
        {name}
      </MenuButton>
      <Menu
        className="CustomMenuIntroduction"
        slotProps={{
          listbox: { className: 'CustomMenuIntroduction--listbox' },
        }}
      >
        {list.map((value) => (
          <MenuItem
            className="CustomMenuIntroduction--item"
            onClick={onItemClick && onItemClick(value)}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
      <Styles />
    </Dropdown>
  );
};

function Styles() {
  return (
    <style>{`
      .CustomMenuIntroduction--listbox {
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        padding: 6px;
        margin: 12px 0;
        min-width: 200px;
        border-radius: 12px;
        overflow: auto;
        outline: 15px;
        background: #060b26;
        color: white;
      }

      .CustomMenuIntroduction--item {
        list-style: none;
        padding: 8px;
        border-radius: 8px;
        cursor: default;
        user-select: none;
      }

      .CustomMenuIntroduction--item:last-of-type {
        border-bottom: none;
      }

      .CustomMenuIntroduction--item:focus {
        background-color: #1a83ff;
        color: dark;
      }

      .CustomMenuIntroduction--item.${menuItemClasses.disabled} {
        color: dark;
      }

      .TriggerButtonIntroduction {
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 8px;
        color: white;
        transition: all 150ms ease;
        cursor: pointer;
        background: none;
        border: none;
        color: white;

        &:hover {
          background: none !important;
          border-color: dark;
        }

        &:active {
          background: none !important;
        }

        &:focus-visible {
          box-shadow: 0 0 0 4px dark;
          outline: none;
        }
      }

      .CustomMenuIntroduction {
        z-index: 99999;
        max-height: 500px;
        overflow-y: auto;
      }
    `}</style>
  );
}

export default DropdownMenu;
