function GetCatId(action, media) {
  let id;
  if (media === "Movies") {
    switch (action) {
      case "Netflix":
        id = "225";
        break;
      case "Amazon Prime":
        id = "228";
        break;
      case "Dysney +":
        id = "226";
        break;
      case "Box Office":
        id = "5";
        break;
      case "Nouveaux Téléchargements":
        id = "311";
        break;
      case "Action - Aventure":
        id = "11";
        break;
      case "Science Fiction":
        id = "16";
        break;
      case "Thriller":
        id = "144";
        break;
      case "Comédie":
        id = "12";
        break;
      case "Horreur":
        id = "15";
        break;
      case "Drame - Romance":
        id = "17";
        break;
      case "Enfant - Jeunesse":
        id = "13";
        break;
      case "Manga":
        id = "18";
        break;
      case "DC Comics":
        id = "64";
        break;
      case "Marvel Studios":
        id = "285";
        break;
      case "Dysney - Pixar":
        id = "14";
        break;
      default:
        id = "5";
    }
  } else if (media === "Series") {
    switch (action) {
      case "Netflix":
        id = "220"; // Correspond à "FR| SÉRIES NETFLIX"
        break;
      case "Amazon Prime":
        id = "218"; // Correspond à "FR| SÉRIES Amazon Prime"
        break;
      case "Dysney +":
        id = "215"; // Correspond à "FR| SÉRIES DISNEY +"
        break;
      case "Canal +":
        id = "276"; // Correspond à "FR| SÉRIES CANAL +"
        break;
      case "Apple TV +":
        id = "221"; // Correspond à "FR| SÉRIES Apple TV+"
        break;
      case "Paramount +":
        id = "333"; // Correspond à "FR| SÉRIES Paramount+"
        break;
      case "Action - Aventure":
        id = "195"; // Correspond à "FR| SÉRIES Action & Adventure"
        break;
      case "Science Fiction":
        id = "194"; // Correspond à "FR| SÉRIES SciFi & Fantastique"
        break;
      case "Thriller - Horreur":
        id = "201"; // Correspond à "FR| SÉRIES THRILLER / HORREUR"
        break;
      case "Comédie":
        id = "196"; // Correspond à "FR| SÉRIES Comédie"
        break;
      case "Animation":
        id = "24"; // Correspond à "FR| SÉRIES Animation"
        break;
      case "Drame":
        id = "197"; // Correspond à "FR| SÉRIES Drame"
        break;
      case "Jeunesse":
        id = "131"; // Correspond à "FR| SÉRIES JEUNESSE"
        break;
      case "Manga":
        id = "25"; // Correspond à "FR| SÉRIES  MANGA"
        break;
      // Ajouter d'autres cas au besoin
      default:
        id = "276"; // Affecter une valeur par défaut si la catégorie n'est pas trouvée
    }
  }
  return id
}

module.exports = GetCatId;
