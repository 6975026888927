// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Movies from "./pages/Movies";
import Navbar from "./components/Navbar/Navbar";
import LoginForm from "./components/Login/Login";
import { useTokenValue } from "./components/Context/Context";
import "./App.css";

const App = () => {
  const { tokenValue } = useTokenValue();

  return (
    <Router>
      {tokenValue ? (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Movies />} />
          </Routes>
        </>
      ) : (
        <LoginForm />
      )}
    </Router>
  );
};

export default App;
