// Importez les dépendances nécessaires
import React from 'react';
import ReactPlayer from 'react-player';

// Définissez le composant YoutubePlayer
const YoutubePlayer = ({ videoUrl }) => {
  return (
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        controls
      />
  );
};

export default YoutubePlayer;
