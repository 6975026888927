import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Navbar.css";
import { IconContext } from "react-icons";
import DropdownMenu from "../dropdown/Dropdown";
import {
  useMediaValue,
  useSearchValue,
  useClickedValue,
} from "../Context/Context";
import Search from "../SearchBar/Search";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const { handleMedia, mediaValue } = useMediaValue();
  const { handleSearch } = useSearchValue();
  const { handleClick } = useClickedValue();

  const showSidebar = () => setSidebar(!sidebar);

  function handleClickLink(media) {
    handleMedia(media);
    handleClick(null);
    handleSearch(null);
  }

  const createHandleMenuClick = (value) => () => {
    handleSearch(null);
    handleClick(value);
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          {mediaValue === "Movies" && (
            <>
              <div className="dropdown">
                <DropdownMenu
                  className="dropdown-content"
                  name="Platformes"
                  list={["Netflix", "Amazon Prime", "Dysney +"]}
                  onItemClick={createHandleMenuClick}
                />
                <DropdownMenu
                  className="dropdown-content"
                  name="Nouveauté"
                  list={["Box Office", "Nouveaux Téléchargements"]}
                  onItemClick={createHandleMenuClick}
                />
                <DropdownMenu
                  className="dropdown-content"
                  name="Genre"
                  list={[
                    "Action - Aventure",
                    "Science Fiction",
                    "Thriller",
                    "Comédie",
                    "Horreur",
                    "Drame - Romance",
                    "Enfant - Jeunesse",
                    "Manga",
                  ]}
                  onItemClick={createHandleMenuClick}
                />
                <DropdownMenu
                  className="dropdown-content"
                  name="Studio"
                  list={["DC Comics", "Marvel Studios", "Dysney - Pixar"]}
                  onItemClick={createHandleMenuClick}
                />
              </div>
            </>
          )}
          {mediaValue === "Series" && (
            <>
              <div className="dropdown">
                <DropdownMenu
                  className="dropdown-content"
                  name="Platformes"
                  list={[
                    "Netflix",
                    "Amazon Prime",
                    "Dysney +",
                    "Canal +",
                    "Apple TV +",
                    "Paramount +",
                  ]}
                  onItemClick={createHandleMenuClick}
                />
                <DropdownMenu
                  className="dropdown-content"
                  name="Genre"
                  list={[
                    "Action - Aventure",
                    "Science Fiction",
                    "Thriller - Horreur",
                    "Comédie",
                    "Animation",
                    "Drame",
                    "Jeunesse",
                    "Manga",
                  ]}
                  onItemClick={createHandleMenuClick}
                />
              </div>
            </>
          )}
          <div className="search">
            <Search />
          </div>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link
                    onClick={() => {
                      handleClickLink(item.media);
                    }}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
