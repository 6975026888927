import React from "react";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";

export const SidebarData = [
  {
    title: "Movies",
    path: "/movies",
    media: "Movies",
    icon: <MdIcons.MdMovie />,
    cName: "nav-text",
  },
  {
    title: "Series",
    path: "/series",
    media: "Series",
    icon: <BiIcons.BiSolidCameraMovie />,
    cName: "nav-text",
  },
  // {
  //   title: "Lives",
  //   path: "/series",
  //   media: "Lives",
  //   icon: <MdIcons.MdLiveTv />,
  //   cName: "nav-text",
  // },
];
