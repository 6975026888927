import React, { useState } from "react";
import Card from "@mui/material/Card";
import { CardActionArea, CardMedia } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useMediaValue } from "../Context/Context";

function Poster({ info, onClick }) {
  const [imageError, setImageError] = useState(false);
  const { mediaValue } = useMediaValue();

  const handleImageError = () => {
    setImageError(!imageError);
  };

  function GetImg() {
    const baseImageUrl = "https://image.tmdb.org/t/p/";
    const baseImageUrlHttp = "http://image.tmdb.org/t/p/";
    let link;

    if (mediaValue === "Movies" || mediaValue === "Lives") {
      link = info.stream_icon;
    } else if (mediaValue === "Series") {
      link = info.cover;
    }

    try {
      if (link.startsWith(baseImageUrl) || link.startsWith(baseImageUrlHttp)) {
        // Mettre à jour la taille de l'image à "original"
        const nouveauLien = link.replace(/\/[a-z]+\d+\//, "/original/");
        link = `api/?action=GetMovieImage&url="${nouveauLien}"`;
        return link;
      } else {
        // Retourner le lien inchangé s'il ne commence pas par l'URL de base de TMDb
      }
    } catch {}
    link = `api/?action=GetMovieImage&url="${link}"`;
    return link;
  }

  function verifierEtModifierChaine(inputString) {
    let nouvelleChaine = inputString;
    const regex1 = /^[a-zA-Z]{2}\|/;
    if (regex1.test(nouvelleChaine)) {
      nouvelleChaine = nouvelleChaine.replace(regex1, "");
    }
    const regex2 = /\(\d+\)/;
    if (regex2.test(nouvelleChaine)) {
      nouvelleChaine = nouvelleChaine.replace(regex2, "");
    }
    const regex3 = /- \d+/;
    if (regex3.test(nouvelleChaine)) {
      nouvelleChaine = nouvelleChaine.replace(regex3, "");
    }
    return nouvelleChaine;
  }

  return (
    <Card
      style={{
        backgroundColor: "transparent",
      }}
    >
      <CardActionArea onClick={onClick}>
        <div style={{ position: "relative" }}>
          <CardMedia
            component="img"
            src={imageError ? "https://i.ibb.co/F5qMnV7/noImage.jpg" : GetImg()}
            style={{
              objectFit: "contain",
              width: "100%",
            }}
            onError={handleImageError}
            key={info.num}
          />
          <Typography
            variant="h6"
            component="div"
            style={{
              textAlign: "center",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
            }}
          >
            {verifierEtModifierChaine(info.name)}
          </Typography>
        </div>
      </CardActionArea>
    </Card>
  );
}

export default Poster;
