import React, { useState, useEffect } from "react";
import "./Overlay.css";
import { FaStar } from "react-icons/fa";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Player from "../Player/ReactPlayer";
import { useMediaValue } from "../Context/Context";
import DropdownMenu from "../dropdown/Dropdown";

const Overlay = ({ onClose, info }) => {
  let s = 0;
  let ep = 0;
  const [showPlayer, setShowPlayer] = useState(false);
  const [showYoutubePlayer, setShowYoutubePlayer] = useState(false);
  const [player, setPlayer] = useState(null);
  const [errorImg, setErrorImg] = useState(false);
  const [playerUrl, setPlayerUrl] = useState();
  const { mediaValue } = useMediaValue();

  if (mediaValue === "Series") {
    s = Object.keys(info.episodes)[0];
    ep = Object.keys(info.episodes)[0][0] + 1;
  }

  const [selectedSeason, setSelectedSeason] = useState(s);
  const [selectedEpisode, setSelectedEpisode] = useState(ep);

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      onClose();
    }
  };

  const handlePlayerClose = (event) => {
    if (event.target.classList.contains("player-overlay")) {
      setShowPlayer(false);
      setShowYoutubePlayer(false);
      if (player) {
        player.pause(); // Pause the video when closing the player
      }
    }
  };

  function showVideo() {
    let name;
    if (mediaValue === "Movies") {
      name = verifierEtModifierChaine(info.movie_data.name);
    } else if (mediaValue === "Series") {
      const epInfo =
        info.episodes[selectedSeason] &&
        info.episodes[selectedSeason][selectedEpisode - 1];
      name = epInfo.title;
    }
    setShowPlayer(true);
    const url = "/bot/sendMessage";
    const data = {
      title: name,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function showTrailer() {
    setShowYoutubePlayer(true);
  }

  function downloadVideo() {
    const link = document.createElement("a");
    link.href = playerUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function copyLink() {
    let url;
    if (mediaValue === "Movies") {
      url = `http://mol-2.com:8080/movie/v0K9BUeNg9Z21W0/wqBXkNkhVdrHrkA/${info.movie_data.stream_id}.${info.movie_data.container_extension}`;
    } else {
      const epInfo =
        info.episodes[selectedSeason] &&
        info.episodes[selectedSeason][selectedEpisode - 1];
      url = `http://mol-2.com:8080/series/v0K9BUeNg9Z21W0/wqBXkNkhVdrHrkA/${epInfo.id}.${epInfo.container_extension}`;
    }
    const textarea = document.createElement("textarea");
    textarea.value = url;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("Lien copié");
  }

  function verifierEtModifierChaine(inputString) {
    let nouvelleChaine = inputString;
    const regex1 = /^[a-zA-Z]{2}\|/;
    if (regex1.test(nouvelleChaine)) {
      nouvelleChaine = nouvelleChaine.replace(regex1, "");
    }
    const regex2 = /\(\d+\)/;
    if (regex2.test(nouvelleChaine)) {
      nouvelleChaine = nouvelleChaine.replace(regex2, "");
    }
    const regex3 = /- \d+/;
    if (regex3.test(nouvelleChaine)) {
      nouvelleChaine = nouvelleChaine.replace(regex3, "");
    }
    return nouvelleChaine;
  }

  useEffect(() => {
    if (mediaValue === "Movies") {
      const name = verifierEtModifierChaine(info.movie_data.name);
      setPlayerUrl(
        `api/?action=GetMovie&name=${name}&id=${info.movie_data.stream_id}&ext=${info.movie_data.container_extension}`
      );
    } else if (mediaValue === "Series") {
      const epInfo =
        info.episodes[selectedSeason] &&
        info.episodes[selectedSeason][selectedEpisode - 1];
      if (epInfo) {
        setPlayerUrl(
          `api/?action=GetSerie&name=${epInfo.title}&id=${epInfo.id}&ext=${epInfo.container_extension}`
        );
      }
    }
  }, [info, selectedEpisode, selectedSeason]);

  function GetImg() {
    const baseImageUrl = "https://image.tmdb.org/t/p/";
    const baseImageUrlHttp = "http://image.tmdb.org/t/p/";
    let link;

    if (mediaValue === "Movies") {
      link = info.info.movie_image;
    } else if (mediaValue === "Series") {
      link = info.info.cover;
    }

    try {
      if (link.startsWith(baseImageUrl) || link.startsWith(baseImageUrlHttp)) {
        // Mettre à jour la taille de l'image à "original"
        const nouveauLien = link.replace(/\/[a-z]+\d+\//, "/original/");
        return nouveauLien;
      } else {
        // Retourner le lien inchangé s'il ne commence pas par l'URL de base de TMDb
      }
    } catch {}
    link = `api/?action=GetMovieImage&url="${link}"`;
    return link;
  }

  function getDurationMin(duration) {
    const durationFloat = duration / 60.0;
    const durMin = Math.floor(durationFloat);

    return durMin;
  }

  function handleErrorImage() {
    setErrorImg(!errorImg);
  }

  function GetSeasons() {
    const seasonNumbers = Object.keys(info.episodes);
    return seasonNumbers.map((seasonNumber) => `Saison ${seasonNumber}`);
  }

  function GetEpisodes() {
    const episodeNumbers = Object.keys(info.episodes[selectedSeason]);
    return episodeNumbers.map(
      (episodeNumber) => `Episode ${parseInt(episodeNumber, 10) + 1}`
    );
  }

  const selectSeason = (value) => () => {
    const numeroSaison = parseInt(value.match(/\d+/)[0], 10);
    setSelectedSeason(numeroSaison);
  };

  const selectEpisode = (value) => () => {
    const numeroEpisode = parseInt(value.match(/\d+/)[0], 10);
    setSelectedEpisode(numeroEpisode);
    const selectedSeasonEpisodes = info.episodes[selectedSeason];
    if (selectedSeasonEpisodes && selectedSeasonEpisodes[selectedEpisode]) {
    } else {
    }
  };

  useEffect(() => {
    setSelectedEpisode(1);
  }, [selectedSeason]);

  return (
    <>
      <div className="overlay" onClick={handleOverlayClick}>
        <div className="overlay-card">
          {showPlayer && (
            <div className="player-overlay" onClick={handlePlayerClose}>
              <div className="player-container">
                <Player videoUrl={playerUrl} />
              </div>
            </div>
          )}
          {showYoutubePlayer && (
            <div className="player-overlay" onClick={handlePlayerClose}>
              <div className="player-container">
                <Player
                  videoUrl={`https://www.youtube.com/watch?v=${info.info.youtube_trailer}`}
                />
              </div>
            </div>
          )}
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
          <img
            className="movie-img"
            src={errorImg ? "https://i.ibb.co/F5qMnV7/noImage.jpg" : GetImg()}
            onError={handleErrorImage}
          />
          <div className="movie-data">
            <div className="movie-info">
              <div className="movie-head">
                <h1 className="movie-title">
                  {verifierEtModifierChaine(info.info.name) ||
                    verifierEtModifierChaine(info.movie_data.name)}
                </h1>
              </div>
              <div className="movie-subdata">
                <div className="movie-left">
                  <p className="movie-stars">
                    <i className="icons">{<FaStar />}</i> {info.info.rating}
                  </p>
                </div>
                <div className="movie-right">
                  {mediaValue === "Movies" && (
                    <>{getDurationMin(info.info.duration_secs)} min</>
                  )}
                </div>
              </div>
              {info.info.plot && (
                <>
                  <h3 className="movie-fields">description</h3>
                  <p className="movie-description">{info.info.plot}</p>
                </>
              )}
            </div>
            <div className="saison-select">
              {mediaValue === "Series" && info.episodes[selectedSeason] && (
                <Stack spacing={2} direction={"row"}>
                  <DropdownMenu
                    className="dropdown-content"
                    name={`Saison ${selectedSeason}`}
                    list={GetSeasons()}
                    onItemClick={selectSeason}
                  />
                  <DropdownMenu
                    className="dropdown-content"
                    name={`Episode ${selectedEpisode}`}
                    list={GetEpisodes()}
                    onItemClick={selectEpisode}
                  />
                </Stack>
              )}
            </div>
            <div className="buttons">
              <Stack
                direction={{ xs: "column", sm: "row" }} // La direction change en fonction de la taille de l'écran
                spacing={2}
              >
                {info.info.youtube_trailer && (
                  <Button variant="contained" onClick={showTrailer}>
                    Trailer
                  </Button>
                )}
                <Button variant="contained" onClick={showVideo}>
                  Voir
                </Button>
                <Button variant="contained" onClick={copyLink}>
                  Copier le lien
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overlay;
